#YesNo_Container {
	display: flex;
	gap: 10rem;

	.con {
		display: flex;
		gap: 1rem;
		align-items: center;

		#check {
			cursor: pointer;

			input {
				display: none;
			}

			svg {
				overflow: visible;
			}
			.path {
				fill: none;
				stroke: rgb(0, 0, 0);
				stroke-width: 6;
				stroke-linecap: round;
				stroke-linejoin: round;
				transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
				stroke-dasharray: 241 9999999;
				stroke-dashoffset: 0;
			}

			input:checked ~ svg .path {
				stroke-dasharray: 70.5096664428711 9999999;
				stroke-dashoffset: -262.2723388671875;
			}
		}
		p {
			font-size: 25px;
			font-weight: 300;
			text-align: left;
		}
	}
}

@media screen and (max-width: 425px) {
	#YesNo_Container {
		gap: 5rem;

		.con {
			p {
				font-size: 21px;
			}
		}
	}
}

@media screen and (min-width: 2561px) {
	#YesNo_Container {
		.con {
			p {
				font-size: 30px;
			}
		}
	}
}
