@font-face {
	font-family: NoirPro;
	src: url("../public/assets/font/NoirPro-Bold.woff2");
	font-weight: bold;
}

@font-face {
	font-family: NoirPro;
	src: url("../public/assets/font/NoirPro-Heavy.woff2");
	font-weight: 900;
}
@font-face {
	font-family: NoirPro;
	src: url("../public/assets/font/NoirPro-Light.woff2");
	font-weight: 300;
}

@font-face {
	font-family: NoirPro;
	src: url("../public/assets/font/NoirPro-Medium.woff2");
	font-weight: 500;
}
@font-face {
	font-family: NoirPro;
	src: url("../public/assets/font/NoirPro-Regular.woff2");
	font-weight: 400;
}

@font-face {
	font-family: NoirPro;
	src: url("../public/assets/font/NoirPro-SemiBold.woff2");
	font-weight: 600;
}
.App {
	text-align: center;
	font-family: "NoirPro";
}
