#HomePage_Container {
	min-height: 100vh;
	color: white;
	max-width: 2560px;
	overflow-x: hidden;

	#Banner {
		overflow-x: hidden;
		padding-left: 5rem;
		background-color: black;
		background-image: url("../../../public/assets/1.png");
		min-height: 100vh;
		width: 100%;
		background-repeat: no-repeat;
		// background-position: top ;
		background-position-x: 20rem;
		background-size: cover;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 2rem;

		img {
			scale: 0.7;
			position: absolute;
			top: 5rem;
			left: 5rem;
		}

		h1 {
			font-weight: 600;
			font-size: 80px;
			max-width: 50%;
			text-transform: capitalize;
			text-align: left;
			margin: 0;
			padding: 0;
		}

		p {
			font-weight: 300;
			font-size: 28px;
			max-width: 700px;
			text-align: left;
			line-height: 40px;
			margin: 0;
			padding: 0;
		}
		#srl_Container {
			display: flex;
			width: calc(100% - 5rem);
			justify-content: center;
			position: absolute;
			inset: unset;
			bottom: 2rem;
			overflow: hidden;
		}
	}

	#Testimonial {
		padding-left: 5rem;
		color: black;
		display: flex;
		flex-direction: column;
		align-self: flex-start;
		justify-content: flex-start;

		margin-bottom: 5rem;

		h2 {
			font-weight: bold;
			font-size: 60px;
			text-align: left;
			padding: 0;
			margin: 0;
			margin-top: 8rem;
		}

		form {
			display: flex;
			flex-direction: column;
			gap: 5rem;
			#info {
				display: flex;
				justify-content: flex-start;
				gap: 5rem;
				flex-wrap: wrap;
				align-items: center;

				select {
					padding: 1rem;
					color: rgb(0, 0, 0);
					font-weight: 300;
					font-size: 20px;
					min-width: 25%;
					background-color: transparent;
					border: none;
					border-bottom: 2px solid rgb(0, 0, 0);
					font-family: "NoirPro";

					option {
						font-size: 20px;
						font-weight: 300;
						font-family: "NoirPro";
						color: black;
						background-color: #ffffff;
					}
				}
				input {
					padding: 1rem;
					color: rgb(0, 0, 0);
					font-weight: 300;
					font-size: 20px;
					min-width: 25%;
					background-color: transparent;
					border: none;
					border-bottom: 2px solid rgb(0, 0, 0);
					font-family: "NoirPro";
				}
			}

			label {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				gap: 1rem;
				font-weight: 400;
				font-size: 30px;
				text-align: left;

				input {
					padding: 2rem;
					margin-top: 2rem;
					color: rgb(0, 0, 0);
					font-weight: 300;
					font-size: 20px;
					min-width: 50%;
					background-color: transparent;
					border: none;
					border: 2px solid rgb(0, 0, 0);
					font-family: "NoirPro";
				}
			}
			#LQ4 {
				display: flex;
				flex-direction: column;
				p {
					font-weight: 400;
					font-size: 30px;
					text-align: left;
				}
				#Q4 {
					display: flex;
					flex-wrap: wrap;
					// justify-content: space-;
					gap: 0 10rem;
				}
			}

			#Check_Container {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 1rem;
				font-size: 30px;
				text-align: left;
				margin-left: 2px;

				#check {
					cursor: pointer;

					input {
						display: none;
					}

					svg {
						overflow: visible;
					}
					.path {
						fill: none;
						stroke: rgb(0, 0, 0);
						stroke-width: 6;
						stroke-linecap: round;
						stroke-linejoin: round;
						transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
						stroke-dasharray: 241 9999999;
						stroke-dashoffset: 0;
					}

					input:checked ~ svg .path {
						stroke-dasharray: 70.5096664428711 9999999;
						stroke-dashoffset: -262.2723388671875;
					}
				}

				p {
					// max-width: 70%;
					text-align: left;
					font-weight: 300;
					font-size: 22px;

					strong {
						font-weight: 500;
					}
				}
			}

			#submit_btn {
				background-color: black;
				color: white;
				width: 50%;
				padding: 1rem;
				border: none;
				font-size: 30px;
				font-family: "NoirPro";

				&:hover {
					cursor: pointer;
				}

				&:disabled {
					background-color: rgba(0, 0, 0, 0.5);
					cursor: auto;
				}
			}
		}
	}
}

@media screen and (max-width: 425px) {
	#HomePage_Container {
		#Banner {
			padding-left: 0;
			background-position-x: -23rem;
			height: auto;
			justify-content: flex-start;
			padding-top: 5vh;
			// min-height: 80vh;
			img {
				margin-left: -7vw;
				// margin-left: -4rem;
				position: inherit;
				scale: 0.5;
				left: 0;
				top: 5rem;
			}
			h1 {
				margin-left: 10vw;
				margin-top: 8rem;
				font-size: 25px;
				max-width: 100%;
				line-height: 35px;
			}
			p {
				margin-left: 10vw;
				font-size: 18px;
				line-height: 35px;
				margin-right: 5rem;
			}
			#srl_Container {
				width: calc(100% - 5vw);
				// bottom: 20vh;
				scale: 0.8;
			}
		}
		#Testimonial {
			padding-left: 5vw;
			h2 {
				font-size: 30px;
				margin-top: 4rem;
			}
			form {
				gap: 3rem;
				#info {
					gap: 2rem;

					select {
						padding: 0.5rem;
						width: 94%;
						font-size: 18px;
					}
					input {
						padding: 0.5rem;
						width: 90%;
						font-size: 18px;
					}
				}
				label {
					text-align: left;
					font-size: 20px;

					input {
						padding: 1rem;
						font-size: 20px;
						width: 85%;
						font-size: 18px;
					}
				}
				#LQ4 {
					p {
						font-size: 20px;
					}
				}

				#Check_Container {
					align-items: flex-start;

					#check {
						margin-top: 1.5rem;
					}

					p {
						font-size: 16px;
						margin-right: 5vw;
					}
				}
				#submit_btn {
					margin-top: -2rem;
					width: 95%;
					font-size: 20px;
				}
			}
		}
	}
}

@media screen and (min-width: 2561px) {
	#HomePage_Container {
		width: auto;
		max-width: none;
		#Banner {
			background-size: 50%;
			background-position: right;
			img {
				scale: 1;
				top: 10rem;
				left: 10rem;
			}

			h1 {
				margin-left: 5rem;
				font-size: 90px;
				max-width: 2000px;
			}
			p {
				margin-left: 5rem;
				font-size: 40px;
				line-height: normal;
				max-width: 2000px;
			}
		}

		#Testimonial {
			padding-left: 10rem;

			h2 {
				font-size: 70px;
			}

			form {
				gap: 8rem;
				#info {
					select {
						font-size: 30px;
					}
					input {
						font-size: 30px;
					}
				}

				label {
					font-size: 37px;

					input {
						font-size: 30px;
					}

					#LQ4 {
						p {
							font-size: 30px;
						}
					}
				}

				#Check_Container {
					margin-top: -5rem;
					margin-bottom: -5rem;
					p {
						font-size: 30px;
						max-width: 2000px;
					}
				}

				#submit_btn {
					padding: 2rem;
					font-size: 40px;
					width: 51.3%;
				}
			}
		}
	}
}

@media screen and (width: 375px) {
	#HomePage_Container {
		#Banner {
			h1 {
				margin-top: 4rem;
			}
		}
	}
}

@media screen and (max-width: 768px) and (min-width: 426px) {
	#HomePage_Container {
		#Banner {
			// padding-left: 3rem;
			background-position-x: -25rem;
			h1 {
				font-size: 60px;
				max-width: none;
			}
			p {
				font-size: 27px;
			}
			img {
				margin-left: -2.5rem;
			}
		}

		#Testimonial {
			padding-right: 5rem;
			h2 {
				font-size: 50px;
			}
			form {
				#info {
					select {
						max-width: none;
						width: 95%;
						font-size: 18px;
					}

					input {
						max-width: none;
						width: 90%;
						font-size: 18px;
					}
				}

				label {
					font-size: 25px;

					input {
						width: 85%;
					}
				}
				#LQ4 {
					margin-right: 5rem;
				}

				#Check_Container {
					align-items: flex-start;
					margin-bottom: -3rem;
					#check {
						margin-top: 2rem;
					}
				}

				#submit_btn {
					width: 95%;
				}
			}
		}
	}
}
